@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/mixins"

























































































































































.v-step
  background: $color-ink !important
  box-shadow: 0 1px 6px rgba(0,0,0,0.5) !important
  border-radius: 8px !important
  filter: none !important
  padding: 24px !important
  text-align: left !important
  z-index: 9999 !important
  max-width: 320px !important

  +mq-s
    position: fixed !important
    box-shadow: unset !important
    border-radius: 0px !important
    transform: unset !important
    top: unset !important
    bottom: 0px !important
    will-change: unset !important
    max-width: 100% !important
    margin-right: 0px !important

  +mq_landscape
    position: fixed !important
    box-shadow: unset !important
    border-radius: 0px !important
    transform: unset !important
    top: 0px !important
    bottom: 0px !important
    left: 65% !important
    will-change: unset !important
    max-width: 100% !important

  +mq-xs
    padding: 16px 24px !important

  &__stop
    color: rgba(255,255,255,.5) !important
    margin-left: -4px
    font-weight: 400

  &__title
    margin-bottom: 8px

  &__body
    letter-spacing: .3px
    line-height: 1.4
    font-weight: 300
    color: rgba(255,255,255,.85)

  .btn--float-dark
    &::before
      content: ""
      position: absolute
      border-bottom: 1px dotted white

  .v-step__arrow

    +mq-s
      display: none

    +mq_landscape
      display: none

  &[x-placement^=bottom]
    margin-top: 12px !important

    +mq_landscape
      margin-top: 0px !important

    .v-step__arrow
      border-bottom-color: $color-ink !important

  &[x-placement^=left]

    .v-step__arrow
      border-left-color: $color-ink !important
      border-width: 24px 0 24px 24px
      right: -8px !important

  &__actions
    margin-top: 24px
    +flex-space-between
    margin-bottom: -8px

    +mq-xs
      margin-top: 0

    &__actions
      +flex-space-between

      .btn ~ .btn
        margin-left: 8px

      .btn--secondary

        +mq_landscape
          display: none

body.in-tour
  pointer-events: none

.tour-active
  position: relative
  z-index: 999
  opacity: 1 !important
  pointer-events: none !important
  user-select: none

  &::before
    border-radius: 4px
    content: ""
    position: absolute
    z-index: -1
    background: inherit
    width: calc(100%)
    height: calc(100%)

  &::after
    content: ""
    position: fixed
    z-index: -2
    opacity: 1 !important
    background: rgba(0,0,0,.4)
    blend-mode: multiply
    width: 100vw
    height: 100vh
    top: 0
    left: 0
    right: 0
    bottom: 0

.tour-active, .v-tour
  pointer-events: auto

.tour-active
  .btn:disabled
    opacity: 1 !important

